@use "../../../../assets/styles/_variables";

#loading {
  align-items: center;
  background: var(--loading-background-color);
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  .loading-side {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    //height: 95%;
    width: 25%;
    overflow: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .scroll-animated {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    h1, h2, h3, h4, h5, h6, p, a {
      color: white;
    }

    img {
      width: 80%;
    }

    @media screen and (max-width: 750px) {
      display: none;
    }
  }

  .loading-middle {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .loading-text {
    text-align: center;
  }

  .loading-heading {
    color: white;
    text-align: center;
  }

  .loading-animation-wrapper {
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 30px 0;
    width: 100%;

    .loading-animation {
      animation: loading-animation 1s infinite linear alternate;
      animation-delay: 0.5s;
      background-color: var(--loading-foreground-color);
      border-radius: 12px;
      color: var(--loading-foreground-color);
      height: 20px;
      position: relative;
      width: 20px;

      &::before {
        animation: loading-animation 1s infinite alternate;
        animation-delay: 0s;
        background-color: var(--loading-foreground-color);
        border-radius: 12px;
        color: var(--loading-foreground-color);
        content: "";
        display: inline-block;
        height: 20px;
        left: -30px;
        position: absolute;
        top: 0;
        width: 20px;
      }

      &::after {
        animation: loading-animation 1s infinite alternate;
        animation-delay: 1s;
        background-color: var(--loading-foreground-color);
        border-radius: 12px;
        color: var(--loading-foreground-color);
        content: "";
        display: inline-block;
        height: 20px;
        left: 30px;
        position: absolute;
        top: 0;
        width: 20px;
      }

      @keyframes loading-animation {
        0% {
          background-color: var(--loading-foreground-color);
        }
        50%, 100% {
          background-color: var(--loading-foreground-dark-color);
        }
      }
    }
  }

  p, li, h1, h2, h3, h4, h5, h6 {
    color: variables.$font-loading;
    opacity: 1;
    text-align: center;
  }

  #loading-azure-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-wrap: anywhere;
    width: 30%;

    button {
      align-self: end;
    }

    @media screen and (max-width: 600px) {
      width: 80%;
    }

    .listWrapper {
      overflow: auto;

      ul {
        padding-left: 15px;
        padding-right: 10px;

        p {
          text-align: left;
        }
      }
    }

    button {
      margin: 10px 0;
    }

    .valid-entropy-number {
      border-bottom: 3px solid white;
      border-top: 3px solid white;
    }

    .azure-validation-number {
      font-size: 35px;
    }
  }
}

#confirm-azure-push-approved {
  align-items: center;
  background: rgb(106 75 173 / 90%);;
  color: rgb(106, 75, 173);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  button {
    align-self: end;
  }
}

.not-connected {
  color: red;
}
