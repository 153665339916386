@use "../../../../../assets/styles/variables";

$breakpoint: 900px;

.onlyOneAuthInfo {
	max-width: 906px;
	background-color: variables.$white;
	display: flex;
	flex-direction: column;
	gap: 20px;
	
	.icon-size {
		width: 30px;
		height: 30px;

		@media screen and (max-width: $breakpoint + 250) {
			width: 20px;
			height: 20px;
		}
	}

	.information {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		p {
			text-align: unset;
		}
	}

	.explanation {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media screen and (max-width: $breakpoint) {
			flex-direction: column;
		}
		
		.explanation-card {
			width: 45%;
			padding: 10px;
			background-color: #f3f4f2;
			border-radius: 6px;
			display: flex;
			gap: 10px;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width: $breakpoint) {
				width: 100%;
			}

			p {
				margin: 0;
			}

			h3 {
				text-align: center;
				margin: 0;
			}
		}

		.current {
			.authInfo {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 10px;
			}
		}

		.plus {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p {
				font-size: 40px;

				@media screen and (max-width: $breakpoint + 50) {
					font-size: 20px;
				}
			}
		}

		.expected {
			.authenticators {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 20px;
			}
		}
	}
}
