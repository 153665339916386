@use "../../../../assets/styles/_variables";

.avatar-card {
  &:not(.no-card) {
    background-color: var(--background-color);
    border: var(--card-border);
    padding: 1rem;
    border-radius: var(--border-radius);
  }

  .name {
    font-weight: 600;
  }

  .description,
  .mobile-number {
    color: var(--text-color-muted);
  }

  .manage-accounts,
  .avatar-image {
    flex-grow: 0;
  }

  .initial-avatar {
    flex-grow: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #d1d5db;
    color: #fff;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  }
}
