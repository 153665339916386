@use "../../../../assets/styles/_variables";

.toggle-wrapper {
  .disabled {
    label.toggle-item {
      background: variables.$lightgray;
    }
    .check {
      background: variables.$darkgray;
    }
    label.toggle-item:before {
      border-color: variables.$darkgray;
    }
    .toggle-input:checked + label {
      .check {
        background: variables.$darkgray;
      }
    }
  }

  .toggle {
    position: relative;
    display: inline-block;
  }

  label.toggle-item {
    width: 60px; 
    height: 31px;
    background: variables.$lightgray;
    border: 1px solid variables.$darkgray;
    display: inline-block;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    transition: all .3s ease;
    transform-origin: 20% center;
    cursor: pointer;

    &:before {
      display: block;
      transition: all .2s ease;
      width: 20px;
      height: 20px;
      top: 5px;
      left: 5px;
      border-radius: 32px;
      border: 2px solid #c34a4a;
      transition: .3s ease;
    }
  }

  input {
    width: 25px;
    height: 25px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .toggle-input:checked + label {
    .check {
      left: 30.7px;
      transform: rotate(360deg);
      background: #8BC34A;
    }
  }

  .check {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    background: #c34a4a;
    transition: .4s ease;
    top: 2.3px;
    left: 2.3px;
  }
}