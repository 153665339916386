@use "../../../../assets/styles/_variables";

.authenticationStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  padding: 10px 15px;
  max-width: 800px;
  margin: 10px 0;
  border: var(--card-border);
  
  .authenticationStatus-content-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .authenticationStatus-content-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  h3, p {
    margin: 0;
  }

  .action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    button {
      width: fit-content;
    }
    
    label {
      margin-bottom: 0!important;
    }
  }
}