@use "../../../../assets/styles/_variables";

.card-group-header {
  background-color: white;
  
  .icon-wrapper {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.success {
      background-color: var(--card-group-header-bakground-success-color);
    }
    &.warning {
      background-color: var(--card-group-header-bakground-warning-color);
    }
    &.info {
      background-color: var(--card-group-header-bakground-info-color);
    }
  }

  .title {
    font-size: 26px;
    font-weight: bold;
    line-height: 140%;
    margin-top: 4px;
  }

  .description {
    font-size: 16px;
    line-height: 160%;
    color: #5E5E72;
  }
}