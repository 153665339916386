@use "../../../../assets/styles/_variables";

.locked {
	background-color: rgba(0,0,0,.5)!important;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
}

.modal-wrapper {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	min-width: 300px;

	@media only screen and (max-width: variables.$screensize-xs) {
		width: 85%;
		min-width: unset;
		
		p {
			font-size: 12px;
		}
	}

	@media only screen and (min-width: variables.$screensize-xs) and (max-width: variables.$screensize-sm) {
		width: 50%;
		min-width: unset;
	}

	.modal {
		background-color: variables.$info-modal-bg;
		color: var(--text-color);
		padding: 20px;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		overflow-wrap: anywhere;

		@media only screen and (max-width: variables.$screensize-xs) {
			padding: 10px;
		}

		.modal-content {
			max-height: 55vh;
			overflow: auto;
			padding-right: 10px;
			width: 100%;
			display: block;
		}

		.modal-buttons {
			display: flex;
			margin-top: 15px;
			justify-content: flex-end;
			gap: 20px;
			
			@media only screen and (max-width: 1200px) {
				flex-direction: column-reverse;
			}

			button {
				min-width: 150px;
			}
		}

		.modal-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;

			.modal-header-button {
				width: 25px;
			}

			.modal-icon-heading-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 10px;
				width: 95%;
				
				
				.modal-header-title {
					width: 100%;
					font-size: 16px;
					padding-left: 5px;
					@media only screen and (max-width: variables.$screensize-xs) {
						width: 75%;
						font-size: 14px;
					}

					& {
						margin: 0;
					}
				}
			}

			.modal-icon,
			.modal-close-button {
				width: 20px;
				height: 20px;
			}
		}
		.modal-close-button:hover {
			cursor: pointer;
		}

	}
}







