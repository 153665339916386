@use "../../../../assets/styles/_variables";

.icon {
  &.icon--colorize {
    path, circle {
      stroke: currentColor;
      fill: none;
    }
  }
}
