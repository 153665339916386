@use "../../../../assets/styles/_variables";

.authenticatorList {
	display: flex;
	flex-direction: column;
	color: variables.$font-secondary;
	
	.column {
		width: 100%;
	}
}

.authenticatorList_header {
	display: flex;
	flex-direction: row;
	background-color: variables.$background-content;
	color: variables.$font-main;

	h3 {
		margin: 10px 0;
	}
}
