@use "../../../../assets/styles/_variables";

.storybook-button {
  font-family: var(--button-font-family);
  font-weight: 700;
  text-transform: var(--button-text-transform);
  border: 0;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 10px 22px;
  font-size: 16px;

  &.button-lm {
    margin-left: 25px;
  }
  
  &.button-mt {
    margin-top: 10px;
  }

  &.button-mb {
    margin-bottom: 10px;
  }
  
  &:disabled {
    cursor: not-allowed;
  }
}

.storybook-button--yellow {
  color: var(--button-label-color);
  background-color: var(--button-background-color);
  border: var(--button-border-width) solid var(--button-border-color);

  &.storybook-button--border {
    border: var(--button-border-width) solid  var(--button-border-accent-color);
  }

  &:hover {
    color: var(--button-label-hover-color);
    border: var(--button-border-width) solid var(--button-border-hover-color);
    background-color: var(--button-background-hover-color);
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--purple {
  color: variables.$button-purple-font;
  background-color: variables.$button-purple-bg;
  border: var(--button-border-width) solid variables.$button-purple-bg;


  &.storybook-button--border {
    border: var(--button-border-width) solid variables.$button-purple-font;
  }

  &:hover {
    color: variables.$button-hover-color;
    border: var(--button-border-width) solid variables.$button-hover-color;
  }

  &:disabled {
    background-color: variables.$button-purple-font;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--white {
  color: var(--button-outline-label-color);
  background-color: var(--button-outline-background-color);
  border: var(--button-border-width) solid var(--button-outline-border-color);


  &.storybook-button--border {
    border: var(--button-border-width) solid var(--button-outline-border-color);
  }

  &:hover {
    color: var(--button-outline-label-hover-color);
    border: var(--button-border-width) solid var(--button-outline-border-hover-color);
    background: var(--button-outline-background-hover-color);
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--transparent {
  color: variables.$button-yellow-font;
  background-color: transparent;
}

.storybook-button--link {
  color: var(--button-outline-label-color);
  background-color: transparent;
  padding: 0 !important;
  font-size: var(--button-font-size);
}

.storybook-button--red {
  color: variables.$button-red-font;
  border: var(--button-border-width) solid variables.$button-red-font;


  &.storybook-button--border {
    border: var(--button-border-width) solid variables.$button-red-font;
  }

  &:hover {
    color: variables.$button-hover-color;
    border: var(--button-border-width) solid variables.$button-hover-color;
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--success {
  color: var(--button-label-color);
  background-color: var(--success-background-color);
  border: var(--button-border-width) solid var(--success-background-color);

  &.storybook-button--border {
    border: var(--button-border-width) solid  var(--success-background-color);
  }

  &:hover {
    color: var(--button-label-hover-color);
    border: var(--button-border-width) solid var(--success-background-hover-color);
    background-color: var(--success-background-hover-color);
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--error {
  color: var(--button-label-color);
  background-color: var(--error-background-color);
  border: var(--button-border-width) solid var(--error-background-color);

  &.storybook-button--border {
    border: var(--button-border-width) solid  var(--error-background-color);
  }

  &:hover {
    color: var(--button-label-hover-color);
    border: var(--button-border-width) solid var(--error-background-hover-color);
    background-color: var(--error-background-hover-color);
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

.storybook-button--warning {
  color: var(--button-label-color-inverted);
  background-color: var(--warning-background-color);
  border: var(--button-border-width) solid var(--warning-background-color);

  &.storybook-button--border {
    border: var(--button-border-width) solid  var(--warning-background-color);
  }

  &:hover {
    color: var(--button-label-hover-color-inverted);
    border: var(--button-border-width) solid var(--warning-background-hover-color);
    background-color: var(--warning-background-hover-color);
  }

  &:disabled {
    background-color: variables.$lightgray;
    border: var(--button-border-width) solid variables.$lightgray;

    &:hover {
      color: variables.$button-yellow-font;
      border: var(--button-border-width) solid variables.$lightgray;
      background-color: variables.$lightgray;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}