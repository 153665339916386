@use "../../../../assets/styles/_variables";

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  margin: 10px 0;

  &.secondary {
    color: variables.$font-secondary;
  }

  &.red {
    color: variables.$red;
    text-decoration: underline;
  }
}
