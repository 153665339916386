@use "../../../../assets/styles/_variables";

.storybook-icon-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	
	h3 {
		color: var(--text-color);
	}

  h2 {
    margin: 0;
    font-size: 24px;

    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  img {
    width: 30px;
    height: 30px;

    transform: rotate(90deg);

    &.active {
      transform: none;
    }
  }
}
