@use "../../../../assets/styles/_variables";


.authenticatorListEntry {
  display: flex;
  flex-direction: column;
  background-color: variables.$background-content;
  color: variables.$font-main;
  padding: 5px 15px;
  border-radius: 6px;
  margin-top: 20px;
  border: var(--card-border);

  &.empty {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: unset;

    @media screen and (max-width: 600px) {
      max-width: 600px;
    }
  }

  .entry-header {
    display: flex;
    flex-direction: column;

    .entry-header-upper-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      .entry-icon {
        width: variables.$icon-size;
        height: variables.$icon-size;
      }

      .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding-left: 8px;
        width: 80%;
      }

      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5%;

        .toggle-container {
          cursor: pointer;
          display: inline-block;
          animation: fade-out 5s;
          height: 24px;
          width: 24px;
          -moz-transition: all .3s ease;
          -ms-transition: all .3s ease;
          -o-transition: all .3s ease;
          -webkit-transition: all .3s ease;
          transition: all .3s ease;

          .line {
            background: variables.$purple;
            display: block;
            height: 2px;
            margin: 5px auto;
            width: 12px;
          }

          .bottom {
            -moz-transform: translateY(2px) rotateZ(225deg);
            -ms-transform: translateY(2px) rotateZ(225deg);
            -o-transform: translateY(2px) rotateZ(225deg);
            -webkit-transform: translateY(2px) rotateZ(225deg);
            transform: translateY(2px) rotateZ(225deg);
          }

          .top {
            -moz-transform: translateY(2px) rotateZ(135deg);
            -ms-transform: translateY(2px) rotateZ(135deg);
            -o-transform: translateY(2px) rotateZ(135deg);
            -webkit-transform: translateY(2px) rotateZ(135deg);
            transform: translateY(2px) rotateZ(135deg);
          }

          &.inactive {
            -moz-transform: translateY(2px) rotate(-90deg);
            -ms-transform: translateY(2px) rotate(-90deg);
            -o-transform: translateY(2px) rotate(-90deg);
            -webkit-transform: translateY(2px) rotate(-90deg);
            transform: translateY(2px) rotate(-90deg);
          }

          &.active {
            -moz-transform: translateY(2px) rotate(-270deg);
            -ms-transform: translateY(2px) rotate(-270deg);
            -o-transform: translateY(2px) rotate(-270deg);
            -webkit-transform: translateY(2px) rotate(-270deg);
            transform: translateY(2px) rotate(-270deg);
          }
        }
      }

    }

    .entry-header-lower-row {
      display: flex;
      margin: 10px 0;
      width: 100%;
      gap: 5px;

      h4, p {
        margin: 0;
      }

      .authenticatorList_content_item_value {
        word-break: break-word;
      }

      .imgAndText {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }

      &.fdr {
        flex-direction: row;
        align-items: center;
      }

      &.fdc {
        flex-direction: column;
        gap: 0;
      }
    }

  }

  .entry-content {
    display: flex;
    flex-direction: row;
  }

  .authenticatorList_name_edit {
    width: 80%;

    input {
      border: none;
      background: variables.$yellow;
      caret-color: variables.$white;
      font-size: 16px;
      font-weight: bold;

      &:focus {
        outline-width: 0;
      }
    }
  }

  .authenticatorList_row {
    .textInput_input {
      width: 100%;
    }

    .authenticatorList_row_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10%;
    }

    .authenticatorList_row_empty {
      display: flex;
      align-items: center;
      width: 90%;
      overflow: hidden;
      margin-right: 10px;

      p {
        color: variables.$font-secondary;
      }
    }
  }

  .authenticatorList_content_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    h4, p {
      margin: 0;
    }

    .authenticatorList_content_item_value {
      word-break: break-word;
    }

    @media screen and (max-width: variables.$breakpoint-mobile-view-authenticator_list) {
      justify-content: flex-start;
    }
  }


  .authenticatorList_content {
    display: flex;
    flex-direction: column;
    background-color: variables.$background-content;
    color: variables.$font-main;

    &.hidden {
      display: none !important;
    }
  }


  .authenticatorList_content_buttons {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: variables.$background-content;

    button {
      width: 100%;
    }


    @media screen and (max-width: variables.$breakpoint-mobile-view-authenticator_list) {
      justify-content: center;
    }

    p {
      text-align: center;
    }
  }

  hr {
    width: 100%;
    border: none;
    margin: 3px 0;

    &.mobile {
      display: none;
      @media screen and (max-width: variables.$breakpoint-mobile-view-authenticator_list) {
        display: block;
      }
    }


    &.light {
      border-bottom: 2px solid variables.$hr-secondary;
    }

    &.dark {
      border-bottom: 2px solid variables.$hr-main;
    }
  }


  .might-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .might-overflow:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }

  .not-in-active-usage-warning {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: #ffc832 solid 2px;
    background-color: #f6edd0;
    padding: 5px 10px;

    p {
      margin: 0;
    }
  }
}