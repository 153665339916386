@use "../../../../assets/styles/_variables";

.radiobutton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  background: var(--background-color);
  max-width: 906px;
  margin: 20px 0;
  z-index: 1;

  &.highlight {
    border: 2px solid variables.$yellow;
    padding: 5px;
  }

  &.no-highlight {
    padding-left: 7px;
  }

  .disabled {
    color: gray;
  }

  .font-weight-normal {
    font-weight: normal;
  }

  .recommended-container {
    margin-top: -17px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;

    .recommended {
      display: inline-block;
      background: variables.$yellow;
      color: var(--text-color);
      font-weight: bold;
      font-size: 12px;
      padding: 3px;
    }

    p {
      background: var(--background-color);
      z-index: 1;
      /* margin: 10px 10px 10px 10px; */
      padding: 0 10px;
      margin-bottom: 0;
      margin-top: 0;

      @media screen and (max-width: 420px) {
        font-size: 8px;
        padding: 0 2px;
      }
    }
  }

  .radiobutton-input-row {
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-weight: bold;
    color: var(--text-color);

    @media screen and (max-width: 420px) {
      padding-top: 5px;
    }

    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      input {
        margin: 0;
        width: 20px;
        height: 20px;
        border: none;
        accent-color: #D43854;
      }
    }

    .image {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;

      &.disabled {
        filter: contrast(0);
      }

      img {
        width: variables.$icon-size;
        height: variables.$icon-size;
      }
    }

    .text {
      word-break: break-all;
    }
  }

  .radiobutton-content-row {
    display: flex;
    flex-direction: row;
    justify-content: left;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    .textonly {
      width: 100%;
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;

      img {
        width: 175px;
      }
    }

    &.listing {
      flex-direction: column;

      .step {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          margin-top: 5px;

          p, div {
            margin: 2px;
          }
        }

        p, div {
          margin-left: 5px;
        }
      }
    }

    &.tabulator {
      width: 75px;
    }

    &.disable-description {
      margin-left: 70px;

      p {
        color: gray;
      }
    }

    .textarea {
      border-radius: 5px;
      border-color: var(--menu-border-color);
      resize: none;
      padding: 10px;
      width: 100%;
      margin-top: 10px;

      &:focus {
        outline: none !important;
      }

      @media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
        font-size: 10px;
      }
    }
  }

  &.no-background {
    background: none !important;
  }
}

.radiobutton_group_no_more_options {
  margin-left: 29px;
}
