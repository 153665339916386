@use "../../../../assets/styles/_variables";

p {
	color: var(--text-color);
	margin: 10px 0;
	text-align: left;

	&.disabled {
		color: grey;
	}

	&.secondary {
		color: variables.$font-secondary;
	}
	
	&.error {
		color: variables.$font-error;
	}
	
	&.strong {
		font-weight: bold;
	}
	
	&.note {
		font-size: 12px;
    line-height: 160%;
	}
}
