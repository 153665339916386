@use "../../../../assets/styles/_variables";

.storybook-info-box--wrapper {
}

.storybook-box {
  padding: var(--infobox-padding);
  border-radius: 6px;
  box-shadow: var(--infobox-box-shadow);
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  overflow-wrap: anywhere;


  .storybook-headline {
    margin: 0 !important;
    line-height: 140%;
  }

  .storybook-subtitle {
    margin-top: 4px !important;
    margin-bottom: 0 !important;
    line-height: 160%;
  } 

  svg {
    flex-shrink: 0;
  }
}
.storybook-info-box {
  background-color: var(--infobox-background-info-color);
  border: var(--infobox-border-info);
  .storybook-headline {
    color: var(--infobox-text-info-color);
  }
}
.storybook-error-box {
  background-color: var(--infobox-background-error-color);
  border: var(--infobox-border-error);
  .storybook-headline {
    color: var(--infobox-text-error-color);
  }
}
.storybook-warning-box {
  background-color: var(--infobox-background-warning-color);
  border: var(--infobox-border-warning);
  .storybook-headline {
    color: var(--infobox-text-warning-color);
  }
}
.storybook-success-box {
  background-color: var(--infobox-background-success-color);
  border: var(--infobox-border-success);
  .storybook-headline {
    color: var(--infobox-text-success-color);
  }
}

.storybook-info-box--content {
  display: flex;
  //text-align: left;
  //justify-content: space-between;
  align-items: flex-start;
  gap: var(--infobox-gap);
}

.storybook-info-box--icon {
  width: 20px;
  height: 20px;
}

.storybook-close {
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.storybook-close-error {
  color: var(--infobox-text-error-color);
}

.storybook-close-info {
  color: var(--infobox-text-info-color);
}

.storybook-close-warning {
  color: var(--infobox-text-warning-color);
}

.storybook-close-success {
  color: var(--infobox-text-success-color);
}

.storybook-info-box--close-button:hover {
  cursor: pointer;
}

.storybook-info-box--buttons {
  display: flex;
  justify-content: flex-end;
}
