@use "../../../../assets/styles/_variables";

footer {
	background: var(--footer-background-color);
	margin-top: auto;
	min-height: 5vh;

	@media screen and (max-width: 480px) {
		clear: left;
		font-size: 12px;
	}

	@media screen and (max-width: 919px) {
		#copyright {
			width: 100%;
		}
	}

	@media screen and (min-width: 920px) {
		/* hide hr on desktop */
		hr {
			display: none;
		}
	}

	a, span {
		color: var(--footer-link-color) !important;
	}

	.main-container-footer {
		text-align: center;
		margin: var(--footer-spacing);
	}

	#versionNumber {
		width: 100%;
		display: flex;
		justify-content: center;
		font-size: 12px;
		margin-top: 5px;
    color: var(--footer-text-color);
	}

	.content-container-footer {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-content: start
	}

	.footer-item {
		flex: none;
		white-space: nowrap;
		padding: 0 10px;

		a {
			text-decoration: underline;
		}
	}

	.pb-0 {
		padding-bottom: 0px !important;
	}

	hr {
		border: 0.5px solid var(--separator-color);
		margin: 12px 0;
	}

	#copyright {
		margin-top: 10px;
		white-space: unset;

		p {
			word-break: break-word;
			text-align: center;
      color: var(--footer-text-color);
		}
	}

	#love {
		display: flex;
		flex-direction: row;
		gap: 5px;
		justify-content: center;
		white-space: unset;
		position: relative;
		cursor: default;
		font-size: 12px;

		p {
			word-break: break-word;
			text-align: center;
			margin: 0px;
			margin-top: 5px;
      color: var(--footer-text-color);
		}
		
		#footer-emoji {
			margin: 0 5px;
			padding-top: 4px;
		}

		#footer-emoji:hover {
			transform: scale(2);
			animation: pulse 2s infinite;
		}
		
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1.5);
	}

	75% {
		transform: scale(1.6);
	}

	100% {
		transform: scale(0.95);
	}
}
