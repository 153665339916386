@use "../../../../assets/styles/_variables";

.textInput {
  display: flex;
  flex-direction: column;

  .textInput_label {
    color: var(--input-label-color);
    font-size: var(--input-label-font-size);
    line-height: 120%;
    margin-bottom: 10px;
  }

  .textInput_input {
    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    height: var(--input-height);
    padding: 8px;

    &:focus {
      outline-width: 0;
    }
  }


  .textInput-notifications {
    display: flex;
    gap: 10px;

    .textInput_message {
      align-self: flex-start;
      margin-top: 10px;

      .textInput_text {
        border-radius: 5px;
        font-size: 12px;
        margin: 0;
        min-width: 48px;
        padding: 8px;

        &.error {
          background-color: var(--error-background-color);
          color: var(--error-link-color);
        }

        &.warning {
          background-color: var(--warning-background-color);
          color: var(--warning-link-color);
        }
      }

      .textInput_corner {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        height: 0;
        position: absolute;
        transform: translate(16px, -7px);
        width: 0;

        &.error {
          border-bottom: 7px solid var(--error-background-color);
        }

        &.warning {
          border-bottom: 7px solid var(--warning-background-color);
        }
      }
    }
  }

  .input-password-wrapper {
    position: relative;

    input {
      width: 100%;
      
      &.icon {
        padding-left: 30px;
      }
    }
    
    .img-left {
      margin: 3px;
      position: absolute;
      left: 5px;
      top: 4px;
    }

    .img-right {
      cursor: pointer;
      margin: 3px;
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }
}