@use "../../../../assets/styles/_variables";

.spacing {
  display: flex;
  width: 100%;
  
  // > * {
  //   @media only screen and (min-width: $screensize-lg) {
  //     flex-grow: 1;
  //   }
  // }

  &.fullWidthChildren > * {
    flex-Grow: 1;
  }
}