@use "../../../../assets/styles/_variables";

.badge {
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  flex-grow: 0 !important;

  &.badge-warning {
    background-color: #FFC832;
  }

  &.badge-success {
    background-color: #01884C;
    color: white;
  }

  &.badge-info {
    background-color: #0F69AF;
    color: white;
  }
}
