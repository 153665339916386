@use "../../../../assets/styles/_variables";


.radiobutton-group {
  margin-top: 25px;
  background: var(--background-color);

  h3 {
    color: var(--text-color);
  }

  .hide {
    display: none !important;
  }

  hr {
    border: 0.5px solid var(--separator-color);
  }

  &.no-background {
    background: none !important;
  }
}
