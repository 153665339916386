@use "../../../../assets/styles/_variables";

.link {
	color: var(--link-color);
	margin: 10px 0;
	text-decoration: underline;
	cursor: pointer;

	&.secondary {
		color: variables.$font-secondary;
	}

	&.gray {
		color: gray;
	}
	
	&.purple {
		color: variables.$font-main !important;
	}

	&:not(.underline) {
		text-decoration: none;
	}

	&.strong {
		font-weight: bold;
	}
}
