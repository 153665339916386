@use "../../../../assets/styles/_variables";

.personal-usercard-wrapper {
  width: 100%;

  .portalUserCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    padding: 10px 15px;
    width: 100%;
    margin: 20px 0;
    gap: 10px;
    border: var(--card-border);

    h3, p {
      margin: 0;
    }

    .personal-usercard-content {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media screen and (max-width: 600px) {
        gap: 20px;
        flex-direction: column;
      }

      .personal-usercard-userinfo-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: space-around;
        padding: 0 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }

        .personal-usercard-userinfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
        }
        
        p {
          word-wrap: break-word;
          white-space: pre-wrap;
          word-break: break-word;
          text-align: center;
        }
      }

      .personal-usercard-buttongroup {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media screen and (max-width: 600px) {
          width: 100%;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}