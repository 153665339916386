@use "../../../../assets/styles/_variables";

.cp-module--wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  
  .cp-module {
    color: variables.$cp-module-font;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media only screen and (max-width: variables.$screensize-xs) {
      display: flex;
      align-items: center;
      text-align: center;
    }

    img {
      width: 150px;
      height: 150px;
    }

    .content {
      max-width: 350px;

      @media only screen and (max-width: variables.$screensize-xs) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;

      .cp-button {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 15px;

        @media only screen and (max-width: variables.$screensize-xs) {
          margin-top: 10px;
        }
      }
    }

  }
}