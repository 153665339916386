@use "../../../../assets/styles/_variables";

.managed-usercard-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 0px;
  }

  .portalUserCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    padding: 10px 15px;
    width: 100%;
    max-width: 390px;
    margin: 10px 0;
    gap: 10px;
    border: var(--card-border);

    @media screen and (max-width: 600px) {
      max-width: 600px;
    }

    h3, p {
      margin: 0;
    }

    .portalUserCard-userinfo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
      width: 100%;
      
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    button {
      width: 100%;
    }
  }
}